import React from "react";
import { graphql } from "gatsby";

import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Layout from "../components/Layout/Layout";
import MarkdownHero from "../components/Hero/MarkdownHero";
import Post from "../components/Post/Post";


const LegalTemplate = ({
  data
}) => {
  const {
    markdownRemark: {
      frontmatter: {
        date,
        description,
        title,
        template
      },
      html
    }
  } = data;
  
  return (
    <Layout
      title={ title }
      description={ description }
    >
      <Header
        colorScheme="light"
      />

      <MarkdownHero
        title={ title }
        template={ template }
        colorScheme="light"
      />

      <Post
        body={ html }
        date={ date }
        template={ template }
      />

      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        description
        title
        template
      }
    }
  }
`;

export default LegalTemplate;